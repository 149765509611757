<template>
  <div @touchend="videoWasTouched" @touchmove="setIsScrolling">
    <video-player
      class="video vjs-big-play-centered"
      :class="surveyStep === 'stimulus' ? 'disable-controls' : ''"
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptions"
      @playing="handlePlaying"
      @pause="handlePause"
      @ended="handleEnded"
      @timeupdate="handleTimeUpdate"
      @loadeddata="setStimulusDuration"      
   >
    </video-player>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { mapGetters, mapMutations } from "vuex";

export default {
  emits: ["startRecording", "stopRecording"],
  props: ["src", "isStimulus"],
  components: {
    videoPlayer,
  },
  data() {
    return {
      topMargin: window.innerWidth > 767 ? "0px" : "-44px",
      bottomMargin: window.innerWidth > 767 ? "0px" : "-49px",
      viewportWidth: window.innerWidth,
      previousPosition: 0,
      observer: null,
      isScrolling: false,
      isPlaying: false,
      replay: 1,
      initialTimestamp: Date.now(),
      findNextSecondStartInterval: null,
    };
  },
  mounted() {
    this.createObserver();
    window.addEventListener("resize", this.onViewportResize);
  },
  beforeDestroy() {
    this.destroyObserver();
    this.surveyStepFinished();
    window.removeEventListener("resize", this.onViewportResize);
  },
  watch: {
    viewportWidth: {
      // This watcher destroys and recreates the intersection observer based on the new width of the window (mobile or desktop header)
      // It's a nasty workaround to update the top + bottom margins of the observer
      handler(newVal, oldVal) {
        if (newVal >= 768 && oldVal <= 767) {
          // Desktop
          this.topMargin = "0px";
          this.bottomMargin = "0px";
          this.destroyObserver();
          this.createObserver();
        }
        if (newVal <= 767 && oldVal >= 768) {
          // Mobile
          this.topMargin = "-44px";
          this.topMargin = "-49px";
          this.destroyObserver();
          this.createObserver();
        }
      },
    },
  },
  computed: {
    ...mapGetters("stimulus", {
      stimulus: "getStimulus",
    }),
    ...mapGetters("instagram", {
      surveyStep: "getSurveyStep",
    }),
    playerOptions() {
      return {
        controls: true,
        fluid: true,
        preload: false,
        muted: true, // needed on ios devices for video.play() to work (Workaround possible???)
        loop: this.surveyStep === "scroll",
        controlBar: {
          children: {
            volumePanel: { inline: false },
          },
        },
        sources: [
          {
            src: this.src,
            type: "video/mp4",
          },
        ],
        poster: "",
      };
    },
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    ...mapMutations("instagram", [
      "SET_SURVEY_STEP",
      "SET_SHOW_PROMPT",
      "SET_SURVEY_STEP_DURATION",
    ]),
    startRecording({ position, replay, timestamp }) {
      this.$parent.$parent.$parent.$emit("startRecording", {
        stimulus: this.stimulus[0],
        position,
        replay,
        timestamp,
        phase: this.surveyStep === "scroll" ? 1 : 2,
      });
    },
    stopRecording() {
      this.$parent.$parent.$parent.$emit("stopRecording", {
        stimulus: this.stimulus[0],
      });
    },
    finishedRecording() {
      this.$parent.$parent.$parent.$emit("finishedRecording");
    },
    onViewportResize() {
      this.viewportWidth = window.innerWidth;
    },
    createObserver() {
      this.observer = new IntersectionObserver(
        (entry) => {
          if (entry[0].isIntersecting) {
            if (this.player.paused()) this.player.play();
          } else if (!entry[0].isIntersecting) {
            if (!this.player.paused()) this.player.pause();
          }
        },
        {
          rootMargin: `${this.topMargin} 0px ${this.bottomMargin} 0px`,
          threshold: [0.6],
        }
      );
      this.observer.observe(this.$refs.videoPlayer.$el);
    },
    destroyObserver() {
      if (this.observer) this.observer.disconnect();
    },
    handlePlaying() {
      if (this.isStimulus) {
        if (this.findNextSecondStartInterval) {
          clearInterval(this.findNextSecondStartInterval);
        }
        this.findNextSecondStartInterval = setInterval(() => {
          if (this.checkFirstDecimal(this.player.currentTime())) {
            this.startRecording({
              position: this.positionRounded(),
              replay: this.replay,
              timestamp: Date.now() - this.initialTimestamp,
              phase: this.surveyStep === "scroll" ? 1 : 2,
            });
            clearInterval(this.findNextSecondStartInterval);
          }
        }, 10);
      }
      this.isPlaying = true;
    },
    checkFirstDecimal(number) {
      const decimalPart = number % 1;
      const decimalString = decimalPart.toString();
      // check millisecond decimal
      if (decimalString[2] === "0" || decimalString[2] === "1") {
        return true;
      } else {
        return false;
      }
    },

    handlePause() {
      if (this.isStimulus) this.stopRecording();
      if (this.findNextSecondStartInterval) {
        clearInterval(this.findNextSecondStartInterval);
      }
      this.isPlaying = false;
    },
    handleEnded() {
      this.SET_SURVEY_STEP("finish");
      this.SET_SHOW_PROMPT(true);
    },
    positionRounded() {
      return Math.round(this.player.currentTime());
    },
    handleTimeUpdate() {
      if (
        this.isStimulus &&
        this.surveyStep === "scroll" &&
        this.previousPosition > this.positionRounded()
      ) {
        this.stopRecording();
        this.replay++;
        this.startRecording({
          position: this.positionRounded(),
          replay: this.replay,
          timestamp: Date.now() - this.initialTimestamp,
          phase: this.surveyStep === "scroll" ? 1 : 2,
        });
        this.previousPosition = this.positionRounded();
      } else {
        this.previousPosition = this.positionRounded();
      }
    },
    surveyStepFinished() {
      this.handlePause();
    },
    setStimulusDuration() {
      if (this.isStimulus) {
        this.SET_SURVEY_STEP_DURATION(Math.floor(this.player.duration()));
      }
    },
    setIsScrolling() {
      this.isScrolling = true;
    },
    videoWasTouched() {
      if (this.surveyStep === "scroll") {
        if (this.isPlaying && !this.isScrolling) {
          this.player.pause();
        } else if (!this.isPlaying && !this.isScrolling) {
          this.player.play();
        }
        this.isScrolling = false;
      }
    },
  },
};
</script>

<style lang="scss">
.video {
  &:hover {
    cursor: pointer;
  }
}
.vjs-control-bar {
  justify-content: end;
  background: transparent !important;
  position: absolute;
}
.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  opacity: 1 !important;
}
.vjs-volume-panel {
  position: absolute;
  right: 20px;
  bottom: 20px;
  .vjs-icon-placeholder::before {
    background-color: rgb(38, 38, 38);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 1.5em;
    padding-top: 2px;
  }
}
.vjs-volume-control {
  display: none !important;
}
.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
}
.vjs-paused .vjs-big-play-button {
  height: 3em;
  width: 3em;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: none;
  background-color: transparent !important;
  .vjs-icon-placeholder::before {
    content: "";
    opacity: 0.95;
    background: url("../../assets/img/Facebook/Play.svg") no-repeat 0 0;
    background-size: 100%;
  }
}
.vjs-loading-spinner {
  display: none !important;
}

.disable-controls {
  .video-js.vjs-playing .vjs-tech {
    pointer-events: none;
  }
  .vjs-control-bar {
    .vjs-play-control {
      pointer-events: none;
    }
  }
}
</style>
